<template>
  <div class="warningMessage">
    <div class="page-title">预警消息管理</div>
    <div class="row-me row-center flex-wrap mT20">
      <div>设备名称</div>
      <div class="w150 mL10">
        <el-input placeholder="请输入名称" style="width: 100%;" clearable v-model="name"></el-input>
      </div>
      <div class="mL20">设备编码</div>
      <div class="w150 mL10">
        <el-input placeholder="请输入设备编码" style="width: 100%;" clearable v-model="code"></el-input>
      </div>
      <div class="mL20">所属组织</div>
      <div class="w200 mL10">
        <el-select placeholder="请选择" style="width: 100%;" filterable clearable v-model="org">
          <el-option v-for="item in orgArray" :key="item.deptId" :label="item.deptName" :value="item.deptId">
          </el-option>
        </el-select>
      </div>
      <div class="mL20">预警时间</div>
      <div class="w400 mL10">
        <el-date-picker type="datetimerange" range-separator="至" start-placeholder="开始时间"
                        value-format="YYYY-MM-DD HH:mm:ss"
                        end-placeholder="结束时间" style="width: 100%;" clearable v-model="date">
        </el-date-picker>
      </div>
      <div class="mL20">预警类型</div>
      <div class="w200 mL10">
        <el-select placeholder="请选择" style="width: 100%;" filterable clearable v-model="type">
          <el-option v-for="item in typeArray" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="row-me row-center mT20">
      <div class="btn-blue w100 btn-height40" @click="clickSearch">查询</div>
      <div class="btn-blank w100 btn-height40 mL20" @click="clickClear">重置</div>
      <div class="btn-blue w100 btn-height40 mL100" @click="clickBatchExport">批量导出</div>
      <div class="btn-blank w100 btn-height40 mL20" @click="clickBatchDelete">批量删除</div>
      <div class="btn-blue w100 btn-height40 mL20" @click="clickBatchDeal">批量处理</div>
      <div class="btn-blue w120 btn-height40 mL20" @click="clickAllDeal">一键全部处理</div>
    </div>
    <div class="column-me mT20">
      <div>
        <el-table :data="tableData" border header-cell-class-name="table-header-style"
                  cell-class-name="table-cell-style" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="60" align="center"/>
          <el-table-column type="index" label="序号" width="80" align="center"/>
          <el-table-column prop="user.nickname" label="绑定用户" align="center" show-overflow-tooltip
                           width="150"></el-table-column>
          <el-table-column prop="user.username" label="真实姓名" align="center" show-overflow-tooltip
                           width="150"></el-table-column>
          <el-table-column label="预警类型" align="center" show-overflow-tooltip width="120">
            <template #default="scope">
             {{
                typeArray.find(item => {
                  return item.id === scope.row.type
                }).name
              }}
            </template>
          </el-table-column>
          <el-table-column prop="msg" label="预警值" align="center" show-overflow-tooltip width="150"></el-table-column>
          <el-table-column prop="createdTime" label="预警时间" align="center" show-overflow-tooltip
                           width="200"></el-table-column>
          <el-table-column prop="watchDevice.name" label="设备名称" align="center"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="watchDevice.code" label="编码" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="watchDevice.postName" label="所属组织" align="center"
                           show-overflow-tooltip width="150"></el-table-column>
          <el-table-column label="操作" align="center" show-overflow-tooltip width="100" fixed="right">
            <template #default="scope">
              <div class="row-me center-all">
                <el-popconfirm title="您确认处理吗？" width="250" @confirm="clickDeal(scope.row)"
                               v-if="scope.row.isException===1">
                  <template #reference>
                    <div class="blue-text">处理</div>
                  </template>
                </el-popconfirm>
                <div v-if="scope.row.isException===0">已处理</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="mT10">
        <el-pagination @current-change="handleCurrentChange" :current-page.sync="pageNo"
                       layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      name: '',
      code: '',
      org: '',
      orgArray: [],
      date: [],
      type: '',
      typeArray: [
        {id: 1, name: '行程预警'}, {id: 2, name: '心率预警(低)'}, {id: 3, name: '血压预警(低)'},
        {id: 4, name: '血氧预警(低)'}, {id: 5, name: '心率预警(高)'}, {id: 6, name: '血压预警(高)'},
        {id: 7, name: '血氧预警(高)'}
      ],
      tableData: [],
      pageNo: 1,
      total: 0,
      multipleSelection: [],
    }
  },
  // 侦听器
  watch: {},
  // 计算属性
  computed: {},
  // html加载完成之前
  created() {
    this.getOrgArray()
    this.getData()
  },
  // html加载完成后执行。
  mounted() {

  },
  // 事件方法执行
  methods: {
    clickSearch() {
      this.pageNo = 1
      this.getData()
    },
    clickClear() {
      this.name = ''
      this.code = ''
      this.org = ''
      this.date = []
      this.type = ''
      this.clickSearch()
    },
    //处理
    clickDeal(item) {
      this.$postForm('/warnings/warningUpdate', {
        id: item.id
      }).then((res) => {
        if (res) {
          this.$message.success('处理完成')
          this.getData()
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.getData()
    },
    handleSelectionChange(e) {
      this.multipleSelection = e
    },
    clickBatchExport() {
      let ids = this.multipleSelection.map(item => {
        return item.id
      })
      this.$postForm('/warnings/watchDerive', {
        ids: ids
      }).then((res) => {
        if (res) {
          window.open(res.data)
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    clickBatchDelete() {
      if (this.multipleSelection.length === 0) {
        this.$message.error("请先选择要删除的条目")
        return
      }
      this.$confirm("您确定要删除吗？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = this.multipleSelection.map(item => {
          return item.id
        })
        this.$postForm('/warnings/watchDeleted', {
          ids: ids,
        }).then((res) => {
          if (res) {
            this.$message.success('删除成功')
            this.getData()
          }
        }).catch((error) => {
          console.log(error)
        })
      }).catch(() => {
      });
    },
    //批量处理
    clickBatchDeal() {
      if (this.multipleSelection.length === 0) {
        this.$message.error("请先选择要处理的条目")
        return
      }
      this.$confirm("您确定批量处理吗？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = this.multipleSelection.map(item => {
          return item.id
        })
        this.$postForm('/warnings/warningUpdateAll', {
          ids: ids,
        }).then((res) => {
          if (res) {
            this.$message.success('批量处理成功')
            this.getData()
          }
        }).catch((error) => {
          console.log(error)
        })
      }).catch(() => {
      });
    },
    //一键全部处理
    clickAllDeal() {
      this.$confirm("您确定全部处理吗？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$postForm('/warnings/warningUpdateOneAll', {}).then((res) => {
          if (res) {
            this.$message.success('一键全部处理成功')
            this.getData()
          }
        }).catch((error) => {
          console.log(error)
        })
      }).catch(() => {
      });
    },
    //获取组织下拉
    getOrgArray() {
      this.$get('/system/dept/list', {}).then((res) => {
        if (res) {
          this.orgArray = res.data
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    getData() {
      this.$postForm('/warnings/warningPage', {
        name: this.name,
        code: this.code,
        organizationId: this.org,
        startTime: this.date.length === 2 ? this.date[0] : '',
        endTime: this.date.length === 2 ? this.date[1] : '',
        type: this.type,
        pageNumber: this.pageNo,
        pageSize: 10,
      }).then((res) => {
        if (res) {
          this.total = Number(res.data.total)
          this.tableData = res.data.records
        }
      }).catch((error) => {
        console.log(error)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.warningMessage {
  height: 100%;
  margin: 20px;
  padding: 20px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
</style>
